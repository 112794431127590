import React from 'react';

import availableAppleStore from '../../../../../../assets/img/app-store.png';
import QRCode from '../../../../../../assets/img/qrcode-buffl-app.svg';

import styles from './Completed.module.css';

const Completed = props => {
  const { translations } = props;

  return (
    <div className={styles.container}>
      <div className={styles.title}>{translations.REFERRAL_COMPLETE_TITLE}</div>
      <div className={styles.text}>
        {translations.ACQUISITION_COMPLETE_DESCRIPTION}
      </div>
      <img className={styles.qrCode} src={QRCode} alt="BUFFL app QR code" />
      <div className={styles.mobileAppsContainer}>
        <div>
          <a
            href="https://itunes.apple.com/be/app/buffl/id1356715033?l=nl&mt=8"
            target="new"
          >
            <img src={availableAppleStore} alt="Buffl on Apple Store" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Completed;
