import React, { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';

import TranslationInputField from '../TranslationInputField/TranslationInputField';
import Footer from '../Footer/Footer';

import styles from './CampaignFailedPage.module.css';

import {
  CUSTOM_THEME_COLOR_NAMES,
  CUSTOM_THEME_IMAGE_NAMES
} from '../../../../../clients/surveys/helpers/constants';

import { GET_CAMPAIGN } from '../../../../graphql/Campaigns';

export default ({
  campaign,
  match,
  location: { search },
  setCampaign,
  setHideBufflLogo,
  customTranslationsLoaded,
  setCustomTranslations,
  translations,
  updateTranslationsLanguage,
  setCustomThemeBaseColor,
  setCustomThemeImage,
  selectedLanguage,
  savingTranslationError,
  setSavingTranslationError,
  clientId
}) => {
  const [resizeTranslationField, setResizeTranslationField] = useState(false);

  const [getCampaign, { loading: loadingResult }] = useLazyQuery(GET_CAMPAIGN, {
    fetchPolicy: 'network-only',
    onCompleted: response => {
      if (response && response.campaign) {
        setCampaign(response.campaign);
      }
    }
  });

  useEffect(() => {
    if (!campaign) {
      getCampaign({
        variables: {
          id: match.params.campaignId,
          referrer: match.params.userId
        }
      });
    }
  }, []);

  useEffect(
    () => {
      if (
        campaign &&
        ((campaign.hideLogoInWebSurvey && campaign.customBranding) ||
          campaign.adaptConsentForExternalInWebSurvey)
      )
        setHideBufflLogo(true);
      if (
        campaign &&
        !(campaign.customCopies && campaign.customCopies.length)
      ) {
        updateTranslationsLanguage(
          campaign.language ||
            localStorage.getItem('campaignLanguage') ||
            selectedLanguage
        );
      }
      if (
        !customTranslationsLoaded &&
        campaign &&
        campaign.customCopies &&
        campaign.customCopies.length
      ) {
        setCustomTranslations(
          campaign.language ||
            localStorage.getItem('campaignLanguage') ||
            selectedLanguage,
          campaign.customCopies
        );
        setResizeTranslationField(customCopyFields.notEligible.name);
      }
      if (customThemeBaseColor) setCustomThemeBaseColor(customThemeBaseColor);
      if (customThemeImage) {
        setCustomThemeImage({
          NAME: customThemeLogoName,
          IMAGE: customThemeLogoImage
        });
      }
    },
    [campaign]
  );

  if (loadingResult && !campaign) {
    return null;
  }

  document.documentElement.scrollTop = 0;

  const customCopyFields = {
    notEligible: {
      name: 'notEligible',
      fields: {
        UNFORTUNATELY_ON_FAILED_PAGE: 'UNFORTUNATELY_ON_FAILED_PAGE',
        YOU_ARE_NOT_ELIGIBLE: 'YOU_ARE_NOT_ELIGIBLE',
        UNFORTUNATELY_YOU_ARE_NOT_ELIGIBLE:
          'UNFORTUNATELY_YOU_ARE_NOT_ELIGIBLE',
        DO_NOT_WORRY_DOZENS_OF_RESEARCHES_FAILED:
          'DO_NOT_WORRY_DOZENS_OF_RESEARCHES_FAILED'
      }
    }
  };

  const isTesting = () => search === '?test=true';

  const customThemeBaseColor =
    campaign &&
    campaign.customBranding &&
    campaign.customThemeColors &&
    campaign.customThemeColors.length &&
    campaign.customThemeColors.find(
      cT => cT.name === CUSTOM_THEME_COLOR_NAMES.BASE
    ).colorCode;

  const customThemeImage =
    campaign &&
    campaign.customBranding &&
    campaign.customThemeImages &&
    campaign.customThemeImages.length;

  const customThemeLogoName =
    campaign &&
    campaign.customBranding &&
    campaign.customThemeImages &&
    campaign.customThemeImages.length &&
    campaign.customThemeImages.find(
      cT => cT.name === CUSTOM_THEME_IMAGE_NAMES.LOGO
    ).name;

  const customThemeLogoImage =
    campaign &&
    campaign.customBranding &&
    campaign.customThemeImages &&
    campaign.customThemeImages.length &&
    campaign.customThemeImages.find(
      cT => cT.name === CUSTOM_THEME_IMAGE_NAMES.LOGO
    ).imageFilename;

  const allowedToEditTranslations =
    campaign &&
    isTesting() &&
    clientId &&
    ((campaign.client && campaign.client === clientId) ||
      (campaign.collaborators &&
        campaign.collaborators.length &&
        campaign.collaborators.find(c => c.id === clientId)));

  const translationInputFieldProps = campaign
    ? {
        surveyId: campaign.id,
        clientId,
        language:
          campaign.language ||
          selectedLanguage ||
          localStorage.getItem('campaignLanguage'),
        disabled: !allowedToEditTranslations || savingTranslationError,
        testing: isTesting(),
        setSavingTranslationError,
        resizeTranslationField,
        setResizeTranslationField
      }
    : {};

  return (
    <div className={styles.parentContainer}>
      <div className={styles.mainContainer}>
        <div className={styles.contentContainer}>
          <TranslationInputField
            name={customCopyFields.notEligible.name}
            translationKey={
              customCopyFields.notEligible.fields
                .UNFORTUNATELY_YOU_ARE_NOT_ELIGIBLE
            }
            value={
              translations[
                customCopyFields.notEligible.fields
                  .UNFORTUNATELY_YOU_ARE_NOT_ELIGIBLE
              ]
            }
            customClasses={`${
              styles.title
            } campaign-user-details-waiting-to-confirm-title`}
            {...translationInputFieldProps}
          />
          <TranslationInputField
            name={customCopyFields.notEligible.name}
            translationKey={
              customCopyFields.notEligible.fields
                .DO_NOT_WORRY_DOZENS_OF_RESEARCHES_FAILED
            }
            value={
              translations[
                customCopyFields.notEligible.fields
                  .DO_NOT_WORRY_DOZENS_OF_RESEARCHES_FAILED
              ]
            }
            customClasses={`${styles.description}`}
            {...translationInputFieldProps}
          />
        </div>
      </div>
      <Footer
        hiddenNext
        hideIllustrationsInWebSurvey={
          campaign &&
          campaign.hideIllustrationsInWebSurvey &&
          campaign.customBranding
        }
        customThemeBaseColor={customThemeBaseColor}
        showBackgroundGrid={{
          showColor: !(campaign && campaign.customBranding)
        }}
      />
    </div>
  );
};
