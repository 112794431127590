import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import ClientRoute from '../../auth/containers/ClientRouteContainer';
import LoginPage from '../../auth/containers/LoginPageContainer';
import RegistrationPage from '../../auth/containers/RegistrationPageContainer';
import RequestPasswordResetPage from '../../auth/components/RequestPasswordResetPage';
import ResetPasswordPage from '../../auth/components/ResetPasswordPage';
import PortalPage from '../../campaigns/containers/PortalPageContainer';
import SurveyBuilderPage from '../../surveys/containers/SurveyBuilderPageContainer';
import CampaignResultsPage from '../../campaigns/containers/CampaignResultsPageContainer';
import ViewCampaignResultsPage from '../../campaigns/components/CampaignResults/ViewCampaignResultsPage';
import NotificationsCenter from '../../portals/CommunicationCenter/containers/NotificationsCenter';
import CouponManagementPage from '../../portals/CouponManagement/containers/CouponManagementPage';
import ProfileTagsManagement from '../../portals/ProfileTagsManagement/containers/ProfileTagsManagement';
import AccountManagement from '../../portals/AccountManagement/containers/AccountManagement';
import ClientsInvitation from '../../auth/containers/ClientsInvitationContainer';
import OAuthLoginPage from '../../auth/containers/OAuthLoginPageContainer';
import CommunityDashboard from '../../portals/CommunityDashboard/containers/CommunityDashboard';
import FeasibilityChecker from '../../portals/FeasibilityChecker/containers/FeasibilityChecker';
import CommunityAutomations from '../../portals/CommunityAutomations/containers/CommunityAutomations';
import CommunityManagement from '../../portals/CommunityManagement/containers/CommunityManagement';
import AcquisitionMetrics from '../../portals/AcquisitionMetrics/containers/AcquisitionMetrics';
import BusinessConfiguration from '../../portals/BusinessConfiguration/containers/BusinessConfiguration';

import CookiesPopup from './CookiesPopup/CookiesPopup';

const Router = ({ webSocketUrl }) => (
  <div style={style.fullHeight}>
    <BrowserRouter basename="/clients">
      <div style={style.fullHeight}>
        <Switch>
          <Route exact path="/auth/login" component={LoginPage} />
          <Route exact path="/auth/register" component={RegistrationPage} />
          <Route
            exact
            path="/auth/password/reset"
            component={RequestPasswordResetPage}
          />
          <Route
            exact
            path="/auth/password/reset/:token"
            component={ResetPasswordPage}
          />
          <Route
            exact
            path="/invitation/:token?"
            component={ClientsInvitation}
          />
          <Route
            exact
            path="/campaigns/:id/view/:viewToken"
            component={ViewCampaignResultsPage}
          />
          <Route
            exact
            path="/oauth/:clientId?/:loginToken?/"
            component={OAuthLoginPage}
          />

          <ClientRoute exact path="/" component={PortalPage} />
          <ClientRoute
            path="/survey/:id/edit/:tab?/:nodeId?/"
            webSocketUrl={webSocketUrl}
            component={SurveyBuilderPage}
          />
          <ClientRoute
            exact
            path="/campaign/:id/results"
            component={CampaignResultsPage}
          />
          <ClientRoute
            exact
            path="/notifications/:page?"
            component={NotificationsCenter}
          />
          <ClientRoute exact path="/coupons" component={CouponManagementPage} />
          <ClientRoute
            exact
            path="/account-management/:customerId?/:teamId?"
            component={AccountManagement}
          />
          <ClientRoute
            exact
            path="/profile-tags-management"
            component={ProfileTagsManagement}
          />
          <ClientRoute
            exact
            path="/acquisition-metrics"
            component={AcquisitionMetrics}
          />
          <ClientRoute
            exact
            path="/community-dashboard"
            component={CommunityDashboard}
          />
          <ClientRoute
            exact
            path="/feasibility-checker"
            component={FeasibilityChecker}
          />
          <ClientRoute
            exact
            path="/community-automations/:automationId?"
            component={CommunityAutomations}
          />
          <ClientRoute
            exact
            path="/community-management"
            component={CommunityManagement}
          />
          <ClientRoute
            exact
            path="/community-management/:communityId?"
            component={CommunityManagement}
          />
          <ClientRoute
            exact
            path="/business-configuration"
            component={BusinessConfiguration}
          />
        </Switch>
        <CookiesPopup />
      </div>
    </BrowserRouter>
  </div>
);

const style = {
  fullHeight: {
    height: '100vh'
  }
};

export default Router;
