import React from 'react';

import Loader from '../../../base/components/Loader/Loader';

import styles from './OAuthLoginPage.module.css';

export default props => {
  const {
    match: {
      params: { clientId, loginToken }
    }
  } = props;

  if (clientId && loginToken) {
    localStorage.setItem('token', loginToken);
    localStorage.setItem('qd_am_selected_record', 'All');

    window.location.href = '/clients';

    return (
      <div className={styles.mainLoadingContainer}>
        <Loader isProcessing />
      </div>
    );
  }

  return null;

  /*
  if (!clientEmail || !invitationType) {
    return (
      <div className={styles.container}>
        <div className={styles.mainContainer}>
          <img src={bufflLogo} alt="BUFFL Logo" className={styles.logo} />
          <div className={styles.invitationContent}>
            <div className={styles.title}>Invitation not valid.</div>
          </div>
        </div>
      </div>
    );
  }
  */
};
