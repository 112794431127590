import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import qs from 'query-string';

import useUserback from '../../../../hooks/useUserback';

import styles from './CampaignsPage.module.css';
import Footer from '../Footer/Footer';
import TermsAndConditions from './components/TermsAndConditions/TermsAndConditions';
import UserDetails from './components/UserDetails/UserDetails';
import ExternalTermsInput from '../../../../form/ExternalTermsInput/ExternalTermsInput';
import AcceptTermsInput from '../../../../form/AcceptTermsInput/AcceptTermsInput';
import TranslationInputField from '../TranslationInputField/TranslationInputField';

import {
  CUSTOM_THEME_COLOR_NAMES,
  CUSTOM_THEME_IMAGE_NAMES
} from '../../../../../clients/surveys/helpers/constants';

import { GET_APP_CONFIGURATION } from '../../../../graphql/Auth';
import { GET_CAMPAIGN, START_WEB_SURVEY } from '../../../../graphql/Campaigns';

export default props => {
  const {
    location: { search },
    match,
    strings,
    history,
    setCampaignComponents,
    result,
    setHideBufflLogo,
    customTranslationsLoaded,
    setCustomTranslations,
    selectedLanguage,
    setSelectedLanguage,
    translations,
    updateTranslationsLanguage,
    setCustomThemeBaseColor,
    setCustomThemeImage,
    setUserEmailSuggestion,
    savingTranslationError,
    setSavingTranslationError,
    clientId
  } = props;

  const pages = {
    TERMS: 'TERMS',
    USER_DETAILS: 'USER_DETAILS',
    CAMPAIGN: 'CAMPAIGN',
    NOT_AVAILABLE: 'NOT_AVAILABLE'
  };

  const languages = {
    ENGLISH: 'English',
    DUTCH: 'Dutch',
    FRENCH: 'French',
    GERMAN: 'German'
  };

  const languageKeys = {
    English: 'EN',
    Dutch: 'NL',
    French: 'FR',
    German: 'DE'
  };

  const customCopyFields = {
    welcome: {
      name: 'welcome',
      fields: {
        WELCOME: 'WELCOME',
        WE_MAKE_YOU_PLEASANT_HERE: 'WE_MAKE_YOU_PLEASANT_HERE',
        GIVE_YOUR_OPINION_THERE_ARE_NO_BAD_ANSWERS:
          'GIVE_YOUR_OPINION_THERE_ARE_NO_BAD_ANSWERS'
      }
    },
    intro: {
      name: 'intro',
      fields: {
        INTRODUCTION: 'INTRODUCTION'
      }
    },
    notAvailable: {
      name: 'notAvailable',
      fields: {
        UNFORTUNATELY_ON_NOT_AVAILABLE_PAGE:
          'UNFORTUNATELY_ON_NOT_AVAILABLE_PAGE',
        THE_SURVEY_YOU_ARE_LOOKING_IS_NO_AVAILABLE:
          'THE_SURVEY_YOU_ARE_LOOKING_IS_NO_AVAILABLE',
        THE_SURVEY_YOU_ARE_LOOKING_IS_UNFORTUNATELY_NO_AVAILABLE:
          'THE_SURVEY_YOU_ARE_LOOKING_IS_UNFORTUNATELY_NO_AVAILABLE',
        DO_NOT_WORRY_DOZENS_OF_RESEARCHES_NOT_AVAILABLE:
          'DO_NOT_WORRY_DOZENS_OF_RESEARCHES_NOT_AVAILABLE'
      }
    }
  };

  useUserback({
    ...match.params
  });

  const [showContent, setShowContent] = useState(false);
  const [campaignStarted, setCampaignStarted] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [viewTerms, setViewTerms] = useState(false);
  const [userDetailsCompleted, setUserDetailsCompleted] = useState(false);
  const [userDetails, setUserDetails] = useState({
    createProfile: false,
    language: selectedLanguage
  });
  const [localCustomCopies, setLocalCustomCopies] = useState([]);
  const [resizeTranslationField, setResizeTranslationField] = useState(false);

  const {
    data: { getAppConfiguration } = {},
    loading: loadingAppConfiguration
  } = useQuery(GET_APP_CONFIGURATION, {
    fetchPolicy: 'network-only'
  });

  const goToFailedPage = () => {
    const { customClientIdentifier, campaignId, userId } = match.params;

    let finalUrl;
    if (customClientIdentifier) {
      const redirectUrl = campaign.redirectionsWebSurvey.campaignQuotaFull;

      if (redirectUrl && redirectUrl !== '') {
        const urlCustomIdentifierSign = redirectUrl.includes('?') ? '&' : '?';
        finalUrl = `${redirectUrl}${urlCustomIdentifierSign}m=${
          match.params.customClientIdentifier
        }`;
      } else {
        finalUrl = `/users/campaign/${campaignId}/user/${userId}/campaign-failed`;
      }

      window.location.href = encodeURI(finalUrl);
    } else {
      finalUrl = `/campaign/${campaignId}/user/${userId}/campaign-failed`;
    }
    if (isTesting()) {
      finalUrl = `${finalUrl}?test=true`;
    }

    history.push(finalUrl);
  };

  const goToPrivacyPolicyFailedPage = () => {
    const { customClientIdentifier, campaignId, userId } = match.params;

    let finalUrl;
    const redirectUrl =
      campaign &&
      campaign.redirectionsWebSurvey &&
      campaign.redirectionsWebSurvey.campaignEndFailed &&
      campaign.redirectionsWebSurvey.campaignEndFailed !== ''
        ? campaign.redirectionsWebSurvey.campaignEndFailed
        : null;

    if (redirectUrl) {
      if (customClientIdentifier) {
        const urlCustomIdentifierSign = redirectUrl.includes('?') ? '&' : '?';
        finalUrl = `${redirectUrl}${urlCustomIdentifierSign}m=${
          match.params.customClientIdentifier
        }`;
      } else {
        finalUrl = redirectUrl;
      }

      window.location.href = encodeURI(finalUrl);
    } else {
      finalUrl = `/campaign/${campaignId}/user/${userId}/campaign-failed`;
    }
    if (isTesting()) {
      finalUrl = `${finalUrl}?test=true`;
    }

    history.push(finalUrl);
  };

  const [startWebSurvey, { startWebSurveyProcessing }] = useMutation(
    START_WEB_SURVEY,
    {
      onCompleted: response => {
        const startWebSurveyResult = response.startWebSurvey;

        if (startWebSurveyResult && startWebSurveyResult.failure) {
          // Go to quota full page
          goToFailedPage();
        } else {
          setCampaignComponents(
            campaign,
            startWebSurveyResult.nextBlock,
            startWebSurveyResult.result
          );
          startCampaignQuestions(
            startWebSurveyResult.nextBlock,
            startWebSurveyResult.result
          );
        }
      },
      onError: () => {
        // Go to quota full page
        goToFailedPage();
      }
    }
  );

  const { data: { campaign } = {}, loading: loadingCampaign } = useQuery(
    GET_CAMPAIGN,
    {
      variables: { id: match.params.campaignId, referrer: match.params.userId },
      onCompleted: () => setShowContent(true),
      onError: () => setShowContent(true),
      fetchPolicy: 'network-only'
    }
  );

  const isTesting = () => search === '?test=true';

  const customThemeBaseColor =
    campaign &&
    campaign.customBranding &&
    campaign.customThemeColors &&
    campaign.customThemeColors.length &&
    campaign.customThemeColors.find(
      cT => cT.name === CUSTOM_THEME_COLOR_NAMES.BASE
    ).colorCode;

  const buttonStyle = {
    backgroundColor: customThemeBaseColor || '#24B2E8',
    color: '#FFFFFF'
  };

  const customThemeImage =
    campaign &&
    campaign.customBranding &&
    campaign.customThemeImages &&
    campaign.customThemeImages.length;

  const customThemeLogoName =
    campaign &&
    campaign.customBranding &&
    campaign.customThemeImages &&
    campaign.customThemeImages.length &&
    campaign.customThemeImages.find(
      cT => cT.name === CUSTOM_THEME_IMAGE_NAMES.LOGO
    ).name;

  const customThemeLogoImage =
    campaign &&
    campaign.customBranding &&
    campaign.customThemeImages &&
    campaign.customThemeImages.length &&
    campaign.customThemeImages.find(
      cT => cT.name === CUSTOM_THEME_IMAGE_NAMES.LOGO
    ).imageFilename;

  const allowedToEditTranslations =
    campaign &&
    isTesting() &&
    clientId &&
    ((campaign.client && campaign.client === clientId) ||
      (campaign.collaborators &&
        campaign.collaborators.length &&
        campaign.collaborators.find(c => c.id === clientId)));

  const translationInputFieldProps = campaign
    ? {
        surveyId: campaign.id,
        clientId,
        language: selectedLanguage || campaign.language,
        disabled: !allowedToEditTranslations || savingTranslationError,
        testing: isTesting(),
        setSavingTranslationError,
        resizeTranslationField,
        setResizeTranslationField
      }
    : {};

  useEffect(() => {
    if (selectedLanguage) {
      localStorage.setItem('campaignLanguage', selectedLanguage);
    }
    const query = qs.parse(search);
    if (
      query &&
      query.userEmailSuggestion &&
      query.userEmailSuggestion.length
    ) {
      setUserEmailSuggestion(query.userEmailSuggestion);
    }
  }, []);

  useEffect(
    () => {
      if (campaign && campaign.language) {
        localStorage.setItem('campaignLanguage', campaign.language);
        setSelectedLanguage(campaign.language);
        if (
          campaign &&
          !(campaign.customCopies && campaign.customCopies.length)
        ) {
          updateTranslationsLanguage(campaign.language || selectedLanguage);
        }
      }
      if (
        campaign &&
        ((campaign.hideLogoInWebSurvey && campaign.customBranding) ||
          campaign.adaptConsentForExternalInWebSurvey)
      )
        setHideBufflLogo(true);
      if (
        !customTranslationsLoaded &&
        campaign &&
        campaign.customCopies &&
        campaign.customCopies.length
      ) {
        setCustomTranslations(
          campaign.language || selectedLanguage,
          campaign.customCopies
        );
        setLocalCustomCopies(campaign.customCopies);
      }
      if (customThemeBaseColor) setCustomThemeBaseColor(customThemeBaseColor);
      if (customThemeImage) {
        setCustomThemeImage({
          NAME: customThemeLogoName,
          IMAGE: customThemeLogoImage
        });
      }
    },
    [campaign]
  );

  const goToUrl = url => {
    let finalUrl = url;
    if (isTesting()) {
      finalUrl = `${url}?test=true`;
    }

    props.history.push(finalUrl);
  };

  const startCampaignQuestions = (
    startWebSurveyBlock,
    startWebSurveyResult
  ) => {
    if (match.params.customClientIdentifier) {
      goToUrl(
        `/result/${startWebSurveyResult.id}/block/${
          startWebSurveyBlock.id
        }/user/${props.match.params.userId}/${match.params.customClientName}/${
          match.params.customClientIdentifier
        }`
      );
    } else {
      goToUrl(
        `/result/${startWebSurveyResult.id}/block/${
          startWebSurveyBlock.id
        }/user/${props.match.params.userId}`
      );
    }
    document.documentElement.scrollTop = 0;
  };

  const changeLanguage = language => {
    setSelectedLanguage(language);
    localStorage.setItem('campaignLanguage', language);
    if (
      campaign &&
      ((campaign.customCopies && campaign.customCopies.length) ||
        (localCustomCopies && localCustomCopies.length))
    ) {
      setCustomTranslations(
        language,
        localCustomCopies && localCustomCopies.length
          ? localCustomCopies
          : campaign.customCopies
      );
      setResizeTranslationField(customCopyFields.welcome.name);
    } else {
      updateTranslationsLanguage(language);
    }

    setUserDetails({
      ...userDetails,
      language
    });
  };

  const showPage = page => {
    switch (page) {
      case pages.NOT_AVAILABLE:
        return (
          !campaign ||
          campaign.status === 'finished' ||
          (campaign.published === 'false' && !isTesting())
        );
      case pages.CAMPAIGN:
        return !campaignStarted && !viewTerms;
      case pages.USER_DETAILS:
        return (
          campaign &&
          ((!result && result !== false) || !userDetailsCompleted) &&
          campaignStarted &&
          termsAccepted
        );
      case pages.TERMS:
        return !campaignStarted && viewTerms;
      default:
        return null;
    }
  };

  const onUpdateLocalCustomCopy = (key, value, name) => {
    let customCopyParentGroup =
      localCustomCopies && localCustomCopies.find(copy => copy.name === name);

    const customCopies = [...localCustomCopies];

    let newTranslation = {
      KEY: key,
      [languageKeys[selectedLanguage]]: value
    };

    if (customCopyParentGroup) {
      customCopies.splice(
        customCopies.findIndex(copy => copy.name === name),
        1
      );
      const cTranslation = customCopyParentGroup.translations.find(
        t => t.KEY === key
      );
      if (cTranslation) {
        newTranslation = {
          ...cTranslation,
          [languageKeys[selectedLanguage]]: value
        };
        const cTranslationIndex = customCopyParentGroup.translations.findIndex(
          t => t.KEY === key
        );
        customCopyParentGroup.translations.splice(cTranslationIndex, 1);
      }
      customCopyParentGroup.translations.push(newTranslation);
    } else {
      customCopyParentGroup = {
        active: true,
        name,
        translations: [newTranslation]
      };
    }

    setCustomTranslations(selectedLanguage || campaign.language, [
      ...customCopies,
      customCopyParentGroup
    ]);
    setLocalCustomCopies([...customCopies, customCopyParentGroup]);
  };

  if (startWebSurveyProcessing || loadingCampaign) return null;

  if (!loadingAppConfiguration && !loadingCampaign && showContent) {
    if (showPage(pages.NOT_AVAILABLE)) {
      return (
        <div className={styles.parentContainer}>
          <div className={styles.mainContainer}>
            <div className={styles.notAvailableContentContainer}>
              <TranslationInputField
                name={customCopyFields.notAvailable.name}
                translationKey={
                  customCopyFields.notAvailable.fields
                    .THE_SURVEY_YOU_ARE_LOOKING_IS_UNFORTUNATELY_NO_AVAILABLE
                }
                value={
                  translations[
                    customCopyFields.notAvailable.fields
                      .THE_SURVEY_YOU_ARE_LOOKING_IS_UNFORTUNATELY_NO_AVAILABLE
                  ]
                }
                customClasses={`${
                  styles.notAvailableTitle
                } campaign-not-available`}
                {...translationInputFieldProps}
              />
              <TranslationInputField
                name={customCopyFields.notAvailable.name}
                translationKey={
                  customCopyFields.notAvailable.fields
                    .DO_NOT_WORRY_DOZENS_OF_RESEARCHES_NOT_AVAILABLE
                }
                value={
                  translations[
                    customCopyFields.notAvailable.fields
                      .DO_NOT_WORRY_DOZENS_OF_RESEARCHES_NOT_AVAILABLE
                  ]
                }
                customClasses={`${styles.notAvailableDescription}`}
                {...translationInputFieldProps}
              />
            </div>
          </div>
          <Footer
            hiddenNext
            hideIllustrationsInWebSurvey={
              campaign &&
              campaign.hideIllustrationsInWebSurvey &&
              campaign.customBranding
            }
            customThemeBaseColor={customThemeBaseColor}
            showBackgroundGrid={{
              showColor: !(campaign && campaign.customBranding)
            }}
          />
        </div>
      );
    }

    if (showPage(pages.CAMPAIGN)) {
      return (
        <div className={styles.parentContainer} key="page-content">
          <div className={styles.mainContainer}>
            <img
              src={campaign.image}
              alt={campaign.image}
              className={styles.campaignImage}
            />
            <div
              className={styles.campaignImage}
              style={{ backgroundImage: `url('${campaign.image}')` }}
            />
            <div className={styles.campaignName}>{campaign.name}</div>
            <div className={styles.campaignDescriptionContainer}>
              <div className={styles.campaignDescriptionFlex}>
                <TranslationInputField
                  name={customCopyFields.welcome.name}
                  translationKey={
                    !campaign.language
                      ? customCopyFields.welcome.fields
                          .WE_MAKE_YOU_PLEASANT_HERE
                      : customCopyFields.welcome.fields
                          .GIVE_YOUR_OPINION_THERE_ARE_NO_BAD_ANSWERS
                  }
                  value={
                    !campaign.language
                      ? translations[
                          customCopyFields.welcome.fields
                            .WE_MAKE_YOU_PLEASANT_HERE
                        ]
                      : translations[
                          customCopyFields.welcome.fields
                            .GIVE_YOUR_OPINION_THERE_ARE_NO_BAD_ANSWERS
                        ]
                  }
                  customClasses={`${styles.campaignDescriptionTranslation}`}
                  {...translationInputFieldProps}
                  onUpdateLocalCustomCopy={(key, value, name) =>
                    onUpdateLocalCustomCopy(key, value, name)
                  }
                />

                <div className={styles.acceptInputContainer}>
                  {campaign && campaign.adaptConsentForExternalInWebSurvey ? (
                    <ExternalTermsInput
                      label={{
                        GOVERNED_BY_BUFFL_POLICY:
                          strings[selectedLanguage].GOVERNED_BY_BUFFL_POLICY,
                        PRIVACY_POLICY:
                          strings[selectedLanguage].PRIVACY_POLICY,
                        BEFORE_PROCEEDING:
                          strings[selectedLanguage].BEFORE_PROCEEDING,
                        AGREE_TO_PROCEED:
                          strings[selectedLanguage].AGREE_TO_PROCEED,
                        YES_I_AGREE: strings[selectedLanguage].YES_I_AGREE,
                        NO_I_DO_NOT_AGREE:
                          strings[selectedLanguage].NO_I_DO_NOT_AGREE
                      }}
                      setViewTerms={setViewTerms}
                      customThemeBaseColor={customThemeBaseColor}
                      onProceed={() => {
                        setTermsAccepted(true);
                        setCampaignStarted(true);
                      }}
                      onDecline={goToPrivacyPolicyFailedPage}
                    />
                  ) : (
                    <AcceptTermsInput
                      name="agree-to-tc"
                      label={{
                        I_AM_AWARE_OF_THE:
                          strings[selectedLanguage].I_AM_AWARE_OF_THE,
                        READ_AND_UNDERSTAND:
                          strings[selectedLanguage].READ_AND_UNDERSTAND,
                        TERMS_AND_CONDITIONS:
                          strings[selectedLanguage].TERMS_AND_CONDITIONS,
                        AND_AGREE_BY_CHECKING_THIS_BOX:
                          strings[selectedLanguage]
                            .AND_AGREE_BY_CHECKING_THIS_BOX,
                        AND_CONFIRM_I_AGREE:
                          strings[selectedLanguage].AND_CONFIRM_I_AGREE,
                        I_AGREE: strings[selectedLanguage].I_AGREE
                      }}
                      active={termsAccepted}
                      onClick={() => setTermsAccepted(!termsAccepted)}
                      setViewTerms={setViewTerms}
                      customThemeBaseColor={customThemeBaseColor}
                    />
                  )}
                </div>

                <div className={styles.languagesSelectorContainer}>
                  {!campaign.language ? (
                    <>
                      <div
                        role="presentation"
                        onClick={() => changeLanguage(languages.DUTCH)}
                        className={`${styles.languageSelector} ${
                          selectedLanguage === languages.DUTCH
                            ? styles.selectedLanguage
                            : ''
                        }`}
                        style={buttonStyle}
                      >
                        <span className={styles.languageSelectorDesktopContent}>
                          NL
                        </span>
                        <div className={styles.languageSelectorMobileContent}>
                          Nederlands
                        </div>
                      </div>
                      <div
                        role="presentation"
                        onClick={() => changeLanguage(languages.FRENCH)}
                        className={`${styles.languageSelector} ${
                          selectedLanguage === languages.FRENCH
                            ? styles.selectedLanguage
                            : ''
                        }`}
                        style={buttonStyle}
                      >
                        <span className={styles.languageSelectorDesktopContent}>
                          FR
                        </span>
                        <div className={styles.languageSelectorMobileContent}>
                          Français
                        </div>
                      </div>
                      <div
                        role="presentation"
                        onClick={() => changeLanguage(languages.ENGLISH)}
                        className={`${styles.languageSelector} ${
                          selectedLanguage === languages.ENGLISH
                            ? styles.selectedLanguage
                            : ''
                        }`}
                        style={buttonStyle}
                      >
                        <span className={styles.languageSelectorDesktopContent}>
                          ENG
                        </span>
                        <div className={styles.languageSelectorMobileContent}>
                          English
                        </div>
                      </div>
                    </>
                  ) : null}
                  {campaign.language &&
                  !campaign.adaptConsentForExternalInWebSurvey ? (
                    <div
                      className={styles.startSelector}
                      style={{
                        backgroundColor: customThemeBaseColor || '#24B2E8',
                        color: '#FFFFFF',
                        opacity: termsAccepted ? 1 : 0.3,
                        cursor: termsAccepted ? 'pointer' : 'default'
                      }}
                      role="presentation"
                      onClick={() => {
                        if (termsAccepted) {
                          setCampaignStarted(true);
                        }
                      }}
                    >
                      {translations.START_SURVEY}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <Footer
            onClickNext={() => {
              if (termsAccepted) {
                setCampaignStarted(true);
              }
            }}
            disabledNext={!termsAccepted}
            hiddenNext={campaign.language}
            nextLabel={translations.NEXT}
            hideIllustrationsInWebSurvey={
              campaign &&
              campaign.hideIllustrationsInWebSurvey &&
              campaign.customBranding
            }
            customThemeBaseColor={customThemeBaseColor}
            showBackgroundGrid={{ showColor: false }}
          />
        </div>
      );
    }

    if (showPage(pages.TERMS)) {
      return (
        <TermsAndConditions
          termsContent={getAppConfiguration.legal.summary}
          campaign={campaign}
          match={match}
          strings={strings}
          languages={languages}
          selectedLanguage={selectedLanguage}
          changeLanguage={changeLanguage}
          setViewTerms={setViewTerms}
          customThemeBaseColor={customThemeBaseColor}
        />
      );
    }

    if (showPage(pages.USER_DETAILS)) {
      return (
        <UserDetails
          translations={translations}
          campaign={campaign}
          match={match}
          strings={strings}
          startWebSurvey={startWebSurvey}
          choices={getAppConfiguration}
          userId={props.match.params.userId}
          history={history}
          isTesting={isTesting}
          onClickBack={() => {
            setCampaignStarted(false);
          }}
          userDetailsCompleted={userDetailsCompleted}
          setUserDetailsCompleted={setUserDetailsCompleted}
          userDetails={userDetails}
          setUserDetails={setUserDetails}
          customThemeBaseColor={customThemeBaseColor}
          translationInputFieldProps={translationInputFieldProps}
          onUpdateLocalCustomCopy={onUpdateLocalCustomCopy}
          search={search}
        />
      );
    }
  }

  return null;
};
